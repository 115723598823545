import React, { Component } from 'react';
class Foot extends Component{

    render(){
        return (
            <footer className="d-none d-md-block bg-black">
            <div className="container">
                <div className="d-flex flex-md-row flex-column justify-content-between" style={{maxWidth: "540px"}}>
                    <div>
                        <ul>
                            <li>
                                <h5>SmartPhones</h5>
                            </li>
                            <li><a href="https://www.oppo.com/au/smartphone-find-x2-pro" target="_blank" rel="noopener noreferrer">OPPO Find X2 Pro</a></li>
                            <li><a href="https://www.oppo.com/au/smartphone-a91" target="_blank" rel="noopener noreferrer">OPPO A91</a></li>
                            <li><a href="https://www.oppo.com/au/smartphone-a9-2020" target="_blank" rel="noopener noreferrer">OPPO A9 2020</a></li>
                            <li><a href="https://www.oppo.com/au/smartphones" target="_blank" rel="noopener noreferrer">See All Smartphones</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <h5>Support</h5>
                            </li>
                            <li><a href="https://www.oppo.com/au/contact" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                            <li><a href="https://support.oppo.com/au/service-center" target="_blank" rel="noopener noreferrer">Customer Service Center</a></li>
                            <li><a href="https://support.oppocare.com.au" target="_blank" rel="noopener noreferrer">Repair Service</a></li>
                            <li><a href="https://support.oppo.com/au/software-update" target="_blank" rel="noopener noreferrer">Software Update</a></li>
                            <li><a href="https://support.oppo.com/au/warranty-check" target="_blank" rel="noopener noreferrer">Warranty Check</a></li>
                            <li><a href="https://support.oppo.com/au/answer/?aid=neu159" target="_blank" rel="noopener noreferrer">Clone Phone</a></li>
                            <li><a href="https://support.oppo.com/au/answer/?aid=neu7838" target="_blank" rel="noopener noreferrer">HeyTap</a></li>
                            <li><a href="https://support.oppo.com/au/popular-topics" target="_blank" rel="noopener noreferrer">FAQ</a></li>
                            <li><a href="https://security.oppo.com" target="_blank" rel="noopener noreferrer">Security Response Center</a></li>
                            <li><a href="https://support.oppo.com/au/warranty-policy" target="_blank" rel="noopener noreferrer">Warranty Policy</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <h5>About OPPO</h5>
                            </li>
                            <li><a href="https://www.oppo.com/au/about" target="_blank" rel="noopener noreferrer">Our Story</a></li>
                            <li><a href="https://www.oppo.com/au/discover/technology" target="_blank" rel="noopener noreferrer">Technology</a></li>
                            <li><a href="https://www.oppo.com/au/newsroom" target="_blank" rel="noopener noreferrer">Newsroom</a></li>
                            <li><a href="https://shop.oppoaustralia.com.au" target="_blank" rel="noopener noreferrer">Shop</a></li>
                        </ul>
                    </div>
                </div>
                <div className="social-media-items">
                        <p>
                            <span><a href="https://www.facebook.com/OPPOMobileAU" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a></span> <span><a
                                    href="https://twitter.com/oppomobileau" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></span> <span><a href="https://www.pinterest.com/oppomobile/"
                                    target="_blank" rel="noopener noreferrer"><i className="fab fa-pinterest"></i></a></span> <span><a href="https://www.instagram.com/oppomobileau/"
                                    target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></span> <span><a href="https://www.youtube.com/user/OPPOAustralia"
                                    target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a></span>
                        </p>
                    </div>
                <hr style={{background: "rgba(255, 255, 255, 0.48)"}}/>
                <div className="d-flex flex-column flex-md-row">
                        <a href="https://www.oppo.com/au/privacy" target="_blank" rel="noopener noreferrer" className="footer-tas">Privacy</a>
                        <a href="https://www.oppo.com/au/terms" target="_blank" rel="noopener noreferrer" className="footer-tas">Terms of Use</a>
                        <p className="footer-tas">
                            Copyright © 2021 OPPO. All rights reserved.
                        </p>
                </div>
            </div>
        </footer>
        )
    }
}

export default Foot;

