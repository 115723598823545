import React, { Component } from 'react';
import LogoUrl from '../static/logo.svg';
import '../scss/header.scss';
class Head extends Component{

    render(){
        return(
            <nav className="navbar navbar-expand-lg bg-black fixed-top px-0 d-none d-md-block">
            <div className="container no-gutter">
                    <div className="text-center brand">
                        <a className="navbar-brand" href="https://www.oppo.com/au/" target="_blank" rel="noopener noreferrer"><img src={LogoUrl} alt="oppo-logo"/></a>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-bar">
                        <span style={{color: "#ffffff"}}><i className="far fa-bars"></i></span>
                    </button>
                    <div className="collapse navbar-collapse" id="nav-bar">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item"><a href="https://www.oppo.com/au/smartphones" className="nav-link" target="_blank" rel="noopener noreferrer">Smartphones</a></li>
                            <li className="nav-item"><a href="https://www.oppo.com/au/accessories" className="nav-link" target="_blank" rel="noopener noreferrer">Accessories</a></li>
                            <li className="nav-item"><a href="https://www.oppo.com/au/about" className="nav-link" target="_blank" rel="noopener noreferrer">About OPPO</a></li>
                            <li className="nav-item"><a href="https://www.coloros.com/au" className="nav-link" target="_blank" rel="noopener noreferrer">ColorOS</a></li>
                            <li className="nav-item"><a href="https://support.oppo.com/au" className="nav-link" target="_blank" rel="noopener noreferrer">Support</a></li>
                            <li className="nav-item"><a href="https://oppopromotions.com.au" className="nav-link" target="_blank" rel="noopener noreferrer">Promotions</a></li>
                            <li className="nav-item"><a href="https://trade-in.oppoaustralia.com.au" className="nav-link" target="_blank" rel="noopener noreferrer">Trade In</a></li>
                            <li className="nav-item"><a href="https://shop.oppoaustralia.com.au" className="nav-link" target="_blank" rel="noopener noreferrer">Shop</a></li>
                        </ul>
                    </div>
            </div>
            <style type="text/css">
                {`
                .bg-black {
                    background-color: #000 !important;
                }
                `}
            </style>
        </nav>
        )
    }
}

export default Head;